var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-container" },
    [
      _c(
        "el-button",
        {
          style: { background: _vm.color, borderColor: _vm.color },
          attrs: { icon: "el-icon-upload", size: "mini", type: "primary" },
          on: {
            click: function ($event) {
              _vm.dialogVisible = true
            },
          },
        },
        [_vm._v("\n        上传图片\n    ")]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-upload",
            {
              staticClass: "editor-slide-upload",
              attrs: {
                multiple: false,
                "file-list": _vm.fileList,
                "show-file-list": true,
                "on-remove": _vm.handleRemove,
                "before-upload": _vm.beforeUpload,
                "auto-upload": true,
                "http-request": _vm.upload,
                accept: ".png,.jpg,.jpeg,.gif",
                action: "",
                "list-type": "picture-card",
              },
            },
            [
              _c("el-button", { attrs: { size: "small", type: "primary" } }, [
                _c("i", { staticClass: "el-icon-plus" }),
                _vm._v("上传图片\n            "),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "center" } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.handleSubmit },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }