var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "news-container container" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.addDataOfficer,
            expression: "!addDataOfficer",
          },
        ],
        staticClass: "views",
      },
      [
        _c(
          "div",
          { staticClass: "topMenus" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.AddDataOfficerPage(true)
                  },
                },
              },
              [_vm._v("新增数据官")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mianTable" },
          [
            _c(
              "el-table",
              {
                attrs: {
                  data: _vm.tableData,
                  border: "",
                  "tooltip-effect": "dark",
                },
                on: { "selection-change": _vm.selectionChange },
              },
              [
                _c("el-table-column", {
                  attrs: { type: "selection", width: "55" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "序号", width: "55" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                scope.$index +
                                  (_vm.fy.pageNum - 1) * _vm.fy.pageSize +
                                  1
                              ) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "name",
                    label: "姓名",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "position",
                    label: "职位",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "操作", width: "150" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.editDataOfficer(scope)
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.delDataOfficer(scope)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "fy" },
              [
                _c("el-pagination", {
                  attrs: {
                    background: "",
                    "current-page": _vm.fy.pageNum,
                    "page-size": _vm.fy.pageSize,
                    layout: "total, prev, pager, next, jumper",
                    total: _vm.fy.total,
                  },
                  on: {
                    "update:currentPage": function ($event) {
                      return _vm.$set(_vm.fy, "pageNum", $event)
                    },
                    "update:current-page": function ($event) {
                      return _vm.$set(_vm.fy, "pageNum", $event)
                    },
                    "current-change": function ($event) {
                      return _vm.getDataOfficerList(_vm.fy.pageNum)
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.addDataOfficer,
            expression: "addDataOfficer",
          },
        ],
        staticClass: "adds",
      },
      [
        _c(
          "el-form",
          {
            ref: "ruleForm",
            attrs: {
              model: _vm.addForm,
              rules: _vm.rules,
              "label-width": "100px",
            },
          },
          [
            _c(
              "div",
              { staticClass: "left" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "姓名：", prop: "name" } },
                  [
                    _c("el-input", {
                      attrs: { type: "textarea", rows: "4" },
                      model: {
                        value: _vm.addForm.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.addForm, "name", $$v)
                        },
                        expression: "addForm.name",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "职位：", prop: "position" } },
                  [
                    _c("el-input", {
                      attrs: { type: "textarea", rows: "8" },
                      model: {
                        value: _vm.addForm.position,
                        callback: function ($$v) {
                          _vm.$set(_vm.addForm, "position", $$v)
                        },
                        expression: "addForm.position",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "照片：",
                      prop: "headUrl",
                      rules: {
                        required: true,
                        message: "请上传照片",
                        trigger: "blur",
                      },
                    },
                  },
                  [
                    _vm.addForm.headUrl
                      ? _c("div", { staticClass: "img-item item" }, [
                          _c("img", { attrs: { src: _vm.addForm.headUrl } }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "delete-btn",
                              on: { click: _vm.deletePic },
                            },
                            [_c("i", { staticClass: "el-icon-close" })]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.addForm.headUrl
                      ? _c(
                          "el-upload",
                          {
                            attrs: {
                              action: "",
                              multiple: false,
                              "auto-upload": false,
                              accept: ".png,.jpg,.jpeg,.gif",
                              "show-file-list": false,
                              "on-change": _vm.uploadFile,
                            },
                          },
                          [
                            _c("div", { staticClass: "add-btn item" }, [
                              _c("i", { staticClass: "el-icon-plus" }),
                              _vm._v(" "),
                              _c("div", [_vm._v("添加图片")]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.submitForm("ruleForm")
                          },
                        },
                      },
                      [_vm._v("发布")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.resetForm("ruleForm")
                          },
                        },
                      },
                      [_vm._v("重置")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.AddDataOfficerPage(false)
                          },
                        },
                      },
                      [_vm._v("取消")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "right" },
              [
                _c(
                  "el-form-item",
                  {
                    staticClass: "content",
                    attrs: { label: "详情页内容：", prop: "brief" },
                  },
                  [
                    _c("tinymce", {
                      staticClass: "m-t-10",
                      attrs: { height: "580px" },
                      model: {
                        value: _vm.addForm.brief,
                        callback: function ($$v) {
                          _vm.$set(_vm.addForm, "brief", $$v)
                        },
                        expression: "addForm.brief",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "codeContent",
                        staticStyle: {
                          background: "#1890ff",
                          borderColor: "#1890ff",
                        },
                        attrs: {
                          icon: "el-icon-upload2",
                          size: "mini",
                          type: "primary",
                        },
                        on: {
                          click: function ($event) {
                            _vm.codeContent = true
                          },
                        },
                      },
                      [_vm._v("\n            添加原内容\n          ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-upload",
                      {
                        attrs: {
                          action: "",
                          multiple: false,
                          "auto-upload": false,
                          "show-file-list": false,
                          "on-change": _vm.uploadVideo,
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "videoContent",
                            staticStyle: {
                              background: "#1890ff",
                              borderColor: "#1890ff",
                            },
                            attrs: {
                              slot: "trigger",
                              icon: "el-icon-video-camera",
                              size: "mini",
                              type: "primary",
                            },
                            slot: "trigger",
                          },
                          [_vm._v("\n              上传视频\n            ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              name: "添加原内容",
              visible: _vm.codeContent,
              width: "900px",
            },
            on: {
              "update:visible": function ($event) {
                _vm.codeContent = $event
              },
              closed: function ($event) {
                _vm.code = ""
              },
            },
          },
          [
            _c("el-input", {
              attrs: { type: "textarea", rows: "18" },
              model: {
                value: _vm.code,
                callback: function ($$v) {
                  _vm.code = $$v
                },
                expression: "code",
              },
            }),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.codeContent = false
                      },
                    },
                  },
                  [_vm._v("取 消")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.sureCodeContent(_vm.code)
                      },
                    },
                  },
                  [_vm._v("确 定")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }