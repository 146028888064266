"use strict";

var _interopRequireDefault = require("D:/1working/dcmm-project/web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addArticle = addArticle;
exports.addCase = addCase;
exports.addCert = addCert;
exports.addDataOfficer = addDataOfficer;
exports.addDataOfficerCase = addDataOfficerCase;
exports.addMeans = addMeans;
exports.addNew = addNew;
exports.addWorkInfo = addWorkInfo;
exports.deleteArticle = deleteArticle;
exports.deleteCase = deleteCase;
exports.deleteCert = deleteCert;
exports.deleteDataOfficer = deleteDataOfficer;
exports.deleteDataOfficerCase = deleteDataOfficerCase;
exports.deleteMeans = deleteMeans;
exports.deleteNew = deleteNew;
exports.deleteWorkInfo = deleteWorkInfo;
exports.getArea = getArea;
exports.getArticleDetail = getArticleDetail;
exports.getArticleList = getArticleList;
exports.getCaseDetail = getCaseDetail;
exports.getCaseList = getCaseList;
exports.getCertDetail = getCertDetail;
exports.getCertList = getCertList;
exports.getDataOfficerCaseDetail = getDataOfficerCaseDetail;
exports.getDataOfficerCaseList = getDataOfficerCaseList;
exports.getDataOfficerDetail = getDataOfficerDetail;
exports.getDataOfficerList = getDataOfficerList;
exports.getMeansDetail = getMeansDetail;
exports.getMeansList = getMeansList;
exports.getNewDetail = getNewDetail;
exports.getNewList = getNewList;
exports.getWorkInfoDetail = getWorkInfoDetail;
exports.getworkInfoList = getworkInfoList;
exports.updateArticle = updateArticle;
exports.updateCase = updateCase;
exports.updateCert = updateCert;
exports.updateDataOfficer = updateDataOfficer;
exports.updateDataOfficerCase = updateDataOfficerCase;
exports.updateMeans = updateMeans;
exports.updateNew = updateNew;
exports.updateWorkInfo = updateWorkInfo;

var _request = _interopRequireDefault(require("@/utils/request"));

// 新闻
// 获取新闻列表
function getNewList(data) {
  return (0, _request.default)({
    url: '/ius/news/getList',
    method: 'post',
    data: data
  });
} // 获取新闻详情


function getNewDetail(params) {
  return (0, _request.default)({
    url: '/ius/news/getDetail',
    method: 'get',
    params: params
  });
} // 新增新闻


function addNew(data) {
  return (0, _request.default)({
    url: '/ius/news/add',
    method: 'post',
    data: data
  });
} // 删除新闻


function deleteNew(data) {
  return (0, _request.default)({
    url: "/ius/news/delete?id=".concat(data),
    method: 'post',
    data: data
  });
} // 修改新闻


function updateNew(data) {
  return (0, _request.default)({
    url: '/ius/news/update',
    method: 'post',
    data: data
  });
} // 工作动态
// 获取工作动态列表


function getworkInfoList(data) {
  return (0, _request.default)({
    url: '/ius/work/info/getList',
    method: 'post',
    data: data
  });
} // 获取工作动态详情


function getWorkInfoDetail(params) {
  return (0, _request.default)({
    url: '/ius/work/info/getDetail',
    method: 'get',
    params: params
  });
} // 新增工作动态


function addWorkInfo(data) {
  return (0, _request.default)({
    url: '/ius/work/info/add',
    method: 'post',
    data: data
  });
} // 删除工作动态


function deleteWorkInfo(data) {
  return (0, _request.default)({
    url: "/ius/work/info/delete?id=".concat(data),
    method: 'post',
    data: data
  });
} // 修改工作动态


function updateWorkInfo(data) {
  return (0, _request.default)({
    url: '/ius/work/info/update',
    method: 'post',
    data: data
  });
} // 资料
// 新增资料


function addMeans(data) {
  return (0, _request.default)({
    url: '/ius/means/add',
    method: 'post',
    data: data
  });
} // 删除工作动态


function deleteMeans(data) {
  return (0, _request.default)({
    url: "/ius/means/delete?id=".concat(data),
    method: 'post',
    data: data
  });
} // 获取资料详情


function getMeansDetail(params) {
  return (0, _request.default)({
    url: '/ius/means/getDetail',
    method: 'get',
    params: params
  });
} // 获取资料列表


function getMeansList(data) {
  return (0, _request.default)({
    url: '/ius/means/getList',
    method: 'post',
    data: data
  });
} // 修改资料


function updateMeans(data) {
  return (0, _request.default)({
    url: '/ius/means/update',
    method: 'post',
    data: data
  });
} // 首席数据官
// 新增首席数据官


function addDataOfficer(data) {
  return (0, _request.default)({
    url: '/ius/data/officer/add',
    method: 'post',
    data: data
  });
} // 删除首席数据官


function deleteDataOfficer(data) {
  return (0, _request.default)({
    url: "/ius/data/officer/delete?id=".concat(data),
    method: 'post',
    data: data
  });
} // 获取首席数据官详情


function getDataOfficerDetail(params) {
  return (0, _request.default)({
    url: '/ius/data/officer/getDetail',
    method: 'get',
    params: params
  });
} // 获取首席数据官列表


function getDataOfficerList(data) {
  return (0, _request.default)({
    url: '/ius/data/officer/getList',
    method: 'post',
    data: data
  });
} // 修改资料


function updateDataOfficer(data) {
  return (0, _request.default)({
    url: '/ius/data/officer/update',
    method: 'post',
    data: data
  });
} // 首席数据官优秀案例
// 新增数据官优秀案例


function addDataOfficerCase(data) {
  return (0, _request.default)({
    url: '/ius/data/officer/case/add',
    method: 'post',
    data: data
  });
} // 删除数据官优秀案例


function deleteDataOfficerCase(data) {
  return (0, _request.default)({
    url: "/ius/data/officer/case/delete?id=".concat(data),
    method: 'post',
    data: data
  });
} // 获取数据官优秀案例详情


function getDataOfficerCaseDetail(params) {
  return (0, _request.default)({
    url: '/ius/data/officer/case/getDetail',
    method: 'get',
    params: params
  });
} // 获取数据官优秀案例列表


function getDataOfficerCaseList(data) {
  return (0, _request.default)({
    url: '/ius/data/officer/case/getList',
    method: 'post',
    data: data
  });
} // 修改数据官优秀案例


function updateDataOfficerCase(data) {
  return (0, _request.default)({
    url: '/ius/data/officer/case/update',
    method: 'post',
    data: data
  });
} // 获证单位
// 新增获证单位


function addCert(data) {
  return (0, _request.default)({
    url: '/ius/cert/add',
    method: 'post',
    data: data
  });
} // 删除获证单位


function deleteCert(data) {
  return (0, _request.default)({
    url: "/ius/cert/delete?id=".concat(data),
    method: 'post',
    data: data
  });
} // 获取获证单位详情


function getCertDetail(params) {
  return (0, _request.default)({
    url: '/ius/cert/getDetail',
    method: 'get',
    params: params
  });
} // 获取获证单位列表


function getCertList(data) {
  return (0, _request.default)({
    url: '/ius/cert/getList',
    method: 'post',
    data: data
  });
} // 修改获证单位


function updateCert(data) {
  return (0, _request.default)({
    url: '/ius/cert/update',
    method: 'post',
    data: data
  });
} // 优秀案例
// 新增优秀案例


function addCase(data) {
  return (0, _request.default)({
    url: '/ius/case/add',
    method: 'post',
    data: data
  });
} // 删除优秀案例


function deleteCase(data) {
  return (0, _request.default)({
    url: "/ius/case/delete?id=".concat(data),
    method: 'post',
    data: data
  });
} // 获取优秀案例详情


function getCaseDetail(params) {
  return (0, _request.default)({
    url: '/ius/case/getDetail',
    method: 'get',
    params: params
  });
} // 获取优秀案例列表


function getCaseList(data) {
  return (0, _request.default)({
    url: '/ius/case/getList',
    method: 'post',
    data: data
  });
} // 修改优秀案例


function updateCase(data) {
  return (0, _request.default)({
    url: '/ius/case/update',
    method: 'post',
    data: data
  });
} // 知识文章
// 新增知识文章


function addArticle(data) {
  return (0, _request.default)({
    url: '/ius/article/add',
    method: 'post',
    data: data
  });
} // 删除知识文章


function deleteArticle(data) {
  return (0, _request.default)({
    url: "/ius/article/delete?id=".concat(data),
    method: 'post',
    data: data
  });
} // 获取知识文章详情


function getArticleDetail(params) {
  return (0, _request.default)({
    url: '/ius/article/getDetail',
    method: 'get',
    params: params
  });
} // 获取知识文章列表


function getArticleList(data) {
  return (0, _request.default)({
    url: '/ius/article/getList',
    method: 'post',
    data: data
  });
} // 修改知识文章


function updateArticle(data) {
  return (0, _request.default)({
    url: '/ius/article/update',
    method: 'post',
    data: data
  });
} // 获取省市区


function getArea(params) {
  return (0, _request.default)({
    url: "/ius/area/getArea?id=".concat(params),
    method: 'get',
    params: params
  });
}