"use strict";

var _interopRequireDefault = require("D:/1working/dcmm-project/web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = void 0;
exports.resetRouter = resetRouter;

var _interopRequireWildcard2 = _interopRequireDefault(require("D:/1working/dcmm-project/web-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

var _layout = _interopRequireDefault(require("@/layout"));

/* Layout */
_vue.default.use(_vueRouter.default);
/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */


var constantRoutes = [{
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/404'));
    });
  },
  hidden: true
}, {
  path: '/',
  component: _layout.default,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    name: 'Dashboard',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/dashboard/index'));
      });
    },
    meta: {
      title: '首页',
      icon: 'icon-home'
    }
  }]
}, {
  path: '/admin',
  component: _layout.default,
  meta: {
    title: '内容管理',
    icon: 'iconxueshengjingsaitianbao'
  },
  children: [{
    path: '/admin/news',
    name: 'news',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/news'));
      });
    },
    meta: {
      title: '平台新闻',
      icon: 'iconxueshengjingsaitianbao'
    }
  }, {
    path: '/admin/dynamicWork',
    name: 'dynamicWork',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/dynamicWork'));
      });
    },
    meta: {
      title: '工作动态',
      icon: 'iconxueshengjingsaitianbao'
    }
  }, {
    path: '/admin/unit',
    name: 'unit',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/unit'));
      });
    },
    meta: {
      title: '获证单位',
      icon: 'iconxueshengjingsaitianbao'
    }
  }, {
    path: '/admin/article',
    name: 'article',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/article'));
      });
    },
    meta: {
      title: '知识文章',
      icon: 'iconxueshengjingsaitianbao'
    }
  }, {
    path: '/admin/download',
    name: 'download',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/download'));
      });
    },
    meta: {
      title: '资料下载',
      icon: 'iconxueshengjingsaitianbao'
    }
  }, {
    path: '/admin/dataOfficer',
    name: 'dataOfficer',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/dataOfficer'));
      });
    },
    meta: {
      title: '首席数据官',
      icon: 'iconxueshengjingsaitianbao'
    }
  }, {
    path: '/admin/dataOfficerCase',
    name: 'dataOfficerCase',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/dataOfficerCase'));
      });
    },
    meta: {
      title: '首席数据官优秀案例',
      icon: 'iconxueshengjingsaitianbao'
    }
  }, {
    path: '/admin/dataManagement',
    name: 'dataManagement',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/dataManagement'));
      });
    },
    meta: {
      title: '数据管理优秀案例',
      icon: 'iconxueshengjingsaitianbao'
    }
  }]
}, {
  path: '*',
  redirect: '/404',
  hidden: true
}];
exports.constantRoutes = constantRoutes;

var createRouter = function createRouter() {
  return new _vueRouter.default({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

var _default = router;
exports.default = _default;