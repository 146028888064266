var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard-container" }, [
    _c("div", { staticClass: "dashboard-text" }, [
      _vm._v(
        _vm._s(_vm.userName ? _vm.userName + " ，" : "") +
          " 您好欢迎来到DCMM江苏省数据管理能力成熟度评估服务平台后台管理系统！"
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }