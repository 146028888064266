"use strict";

var _interopRequireDefault = require("D:/1working/dcmm-project/web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getPageTitle;

var _settings = _interopRequireDefault(require("@/settings"));

var title = _settings.default.title || 'DCMM江苏省数据管理能力成熟度评估服务平台后台管理系统';

function getPageTitle(pageTitle) {
  if (pageTitle) {
    return "".concat(pageTitle, " - ").concat(title);
  }

  return "".concat(title);
}