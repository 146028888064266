"use strict";

var _interopRequireDefault = require("D:/1working/dcmm-project/web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _axios = _interopRequireDefault(require("axios"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Login',
  data: function data() {
    var validatePassword = function validatePassword(rule, value, callback) {
      if (value.length < 6) {
        callback(new Error('密码不能小于6位！'));
      } else {
        callback();
      }
    };

    return {
      imgs: "",
      loginForm: {
        loginName: '',
        loginPassword: '',
        verificationCode: ''
      },
      loginRules: {
        loginName: [{
          required: true,
          trigger: 'blur',
          message: '请输入用户名'
        }],
        loginPassword: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword
        }],
        verificationCode: [{
          required: true,
          trigger: 'blur',
          message: '请输入验证码'
        }]
      },
      loading: false,
      passwordType: 'password',
      redirect: undefined
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  mounted: function mounted() {
    window.bbb = this;
    this.changeVerify();
  },
  methods: {
    // 生成验证码
    changeVerify: function changeVerify() {
      var _this = this;

      (0, _axios.default)({
        method: "get",
        url: "/api/kaptcha/createKaptcha",
        responseType: 'blob'
      }).then(function (res) {
        var img = document.createElement("img");
        var blob = new Blob([res.data], {
          type: "image/png"
        });
        var url = window.URL.createObjectURL(blob);
        _this.imgs = url; // setTimeout(() => {
        //   this.changeVerify();
        //   this.$message.info("验证码已更新，请重新输入")
        // }, 6000);
        // this.imgs = res.data;
      }).catch(function (err) {
        console.error(err);
      });
    },
    showPwd: function showPwd() {
      var _this2 = this;

      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }

      this.$nextTick(function () {
        _this2.$refs.password.focus();
      });
    },
    handleLogin: function handleLogin() {
      var _this3 = this;

      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          // 校对验证码
          (0, _axios.default)({
            method: "post",
            url: "/api/kaptcha/checkVerificationCode",
            params: {
              verificationCode: _this3.loginForm.verificationCode
            }
          }).then(function (res) {
            if (res.data.ok) {
              _this3.loading = true;

              _this3.$store.dispatch('user/login', _this3.loginForm).then(function (res) {
                // console.error(res);
                _this3.$router.push({
                  path: _this3.redirect || '/',
                  query: {
                    res: res
                  }
                });

                _this3.loading = false;
              }).catch(function () {
                _this3.loading = false;
              });
            } else {
              _this3.$message.error(res.data.message);

              _this3.changeVerify();

              _this3.loginForm.verificationCode = '';
              _this3.loading = false;
            } // setTimeout(() => {
            //   this.changeVerify();
            //   this.$message.info("验证码已更新，请重新输入")
            // }, 6000);
            // this.imgs = res.data;

          }).catch(function (err) {
            console.error(err);
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;