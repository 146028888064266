"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var access_key_id = 'XZCCOAQMKRBF3FBK9IEL';
var secret_access_key = 'kubKTzj3H8jW03VCTzMUglQ6A34OuiciWsZDTIbG';
var host = 'obs.cn-east-3.myhuaweicloud.com';
var server = "https://".concat(host);
var Bucket = 'dcmm'; // let access_key_id = "TU8K2IKKUHQVZTXRPM8E";
// let secret_access_key = "M820jLOFE14GFvh7zRrGaIPhWqc2oFqm9O5AGk3k";
// let host = "obs.cn-east-3.myhuaweicloud.com";
// let server = `https://obs.cn-east-3.myhuaweicloud.com`;
// var Bucket = "f-test";
// eslint-disable-next-line

var obsClient = new ObsClient({
  access_key_id: access_key_id,
  secret_access_key: secret_access_key,
  server: server
}); //获取文件后缀

function get_suffix(filename) {
  var pos = filename.lastIndexOf('.');
  var suffix = '';

  if (pos != -1) {
    suffix = filename.substring(pos);
  }

  return suffix;
} //生成随机字符串


function random_string(len) {
  len = len || 32;
  var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
  var maxPos = chars.length;
  var pwd = '';

  for (var i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos));
  }

  return pwd;
} //更具当前日期生成文件目录


function get_fileDir() {
  var date = new Date();
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  if (month < 10) month = '0' + month;
  if (day < 10) day = '0' + day;
  return "".concat(year, "/").concat(month, "/").concat(day, "/");
}

function put(file, needUrl) {
  return new Promise(function (resolve, reject) {
    var Key = get_fileDir() + random_string(16) + get_suffix(file.name);
    obsClient.putObject({
      Bucket: Bucket,
      Key: Key,
      SourceFile: file
    }, function (err, result) {
      if (err) {
        reject(err);
      } else {
        if (result.CommonMsg.Status < 300) {
          if (needUrl) resolve("https://".concat(Bucket, ".").concat(host, "/").concat(Key));
          resolve(Key);
        } else {
          reject(result.CommonMsg.Message);
        }
      }
    });
  });
}

var _default = {
  put: put
};
exports.default = _default;