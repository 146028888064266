"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  device: function device(state) {
    return state.app.device;
  },
  token: function token(state) {
    return state.user.token;
  },
  userName: function userName(state) {
    return state.user.userInfo.userName;
  },
  userInfo: function userInfo(state) {
    return state.user.userInfo;
  }
};
var _default = getters;
exports.default = _default;