"use strict";

var _interopRequireDefault = require("D:/1working/dcmm-project/web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/1working/dcmm-project/web-admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));

var _vuex = require("vuex");

//
//
//
//
//
//
var _default = {
  name: 'Dashboard',
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['userName']))
};
exports.default = _default;