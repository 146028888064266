"use strict";

var _interopRequireDefault = require("D:/1working/dcmm-project/web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/1working/dcmm-project/web-admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));

var _vuex = require("vuex");

var _Breadcrumb = _interopRequireDefault(require("@/components/Breadcrumb"));

var _Hamburger = _interopRequireDefault(require("@/components/Hamburger"));

//
//
//
//
//
//
//
//
var _default = {
  components: {
    Breadcrumb: _Breadcrumb.default,
    Hamburger: _Hamburger.default
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['sidebar', 'avatar'])),
  methods: {
    toggleSideBar: function toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    }
  }
};
exports.default = _default;