var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "news-container container" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.addNews,
            expression: "!addNews",
          },
        ],
        staticClass: "views",
      },
      [
        _c(
          "div",
          { staticClass: "topMenus" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.AddWorkInfoPage(true)
                  },
                },
              },
              [_vm._v("新增工作动态")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mianTable" },
          [
            _c(
              "el-table",
              {
                attrs: {
                  data: _vm.tableData,
                  border: "",
                  "default-sort": { prop: "pubTime", order: "descending" },
                  "tooltip-effect": "dark",
                },
                on: {
                  "selection-change": _vm.selectionChange,
                  "sort-change": _vm.sort,
                },
              },
              [
                _c("el-table-column", {
                  attrs: { type: "selection", width: "55" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "序号", width: "55" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                scope.$index +
                                  (_vm.fy.pageNum - 1) * _vm.fy.pageSize +
                                  1
                              ) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "title",
                    label: "标题名称",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "brief",
                    label: "内容描述",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "pubTime",
                    label: "发布时间",
                    sortable: "custom",
                    width: "180",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "pubUser",
                    label: "发布作者",
                    "show-overflow-tooltip": "",
                    width: "150",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "操作", width: "150" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.editNews(scope)
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.delNews(scope)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "fy" },
              [
                _c("el-pagination", {
                  attrs: {
                    background: "",
                    "current-page": _vm.fy.pageNum,
                    "page-size": _vm.fy.pageSize,
                    layout: "total, prev, pager, next, jumper",
                    total: _vm.fy.total,
                  },
                  on: {
                    "update:currentPage": function ($event) {
                      return _vm.$set(_vm.fy, "pageNum", $event)
                    },
                    "update:current-page": function ($event) {
                      return _vm.$set(_vm.fy, "pageNum", $event)
                    },
                    "current-change": function ($event) {
                      return _vm.getworkInfoList(_vm.fy.pageNum, _vm.nowSort)
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.addNews,
            expression: "addNews",
          },
        ],
        staticClass: "adds",
      },
      [
        _c(
          "el-form",
          {
            ref: "ruleForm",
            attrs: {
              model: _vm.addForm,
              rules: _vm.rules,
              "label-width": "100px",
            },
          },
          [
            _c(
              "div",
              { staticClass: "left" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "标题名称：", prop: "title" } },
                  [
                    _c("el-input", {
                      attrs: { type: "textarea", rows: "4" },
                      model: {
                        value: _vm.addForm.title,
                        callback: function ($$v) {
                          _vm.$set(_vm.addForm, "title", $$v)
                        },
                        expression: "addForm.title",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "内容描述：", prop: "brief" } },
                  [
                    _c("el-input", {
                      attrs: { type: "textarea", rows: "8" },
                      model: {
                        value: _vm.addForm.brief,
                        callback: function ($$v) {
                          _vm.$set(_vm.addForm, "brief", $$v)
                        },
                        expression: "addForm.brief",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "发布时间：", prop: "pubTime" } },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "datetime",
                        "value-format": "yyyy-MM-dd HH:mm:ss",
                        placeholder: "选择日期时间",
                      },
                      model: {
                        value: _vm.addForm.pubTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.addForm, "pubTime", $$v)
                        },
                        expression: "addForm.pubTime",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "发布作者：", prop: "pubUser" } },
                  [
                    _c("el-input", {
                      staticClass: "authors",
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.addForm.pubUser,
                        callback: function ($$v) {
                          _vm.$set(_vm.addForm, "pubUser", $$v)
                        },
                        expression: "addForm.pubUser",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.submitForm("ruleForm")
                          },
                        },
                      },
                      [_vm._v("发布")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.resetForm("ruleForm")
                          },
                        },
                      },
                      [_vm._v("重置")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.AddWorkInfoPage(false)
                          },
                        },
                      },
                      [_vm._v("取消")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "right" },
              [
                _c(
                  "el-form-item",
                  {
                    staticClass: "content",
                    attrs: { label: "发布内容：", prop: "content" },
                  },
                  [
                    _c("tinymce", {
                      staticClass: "m-t-10",
                      attrs: { height: "580px" },
                      model: {
                        value: _vm.addForm.content,
                        callback: function ($$v) {
                          _vm.$set(_vm.addForm, "content", $$v)
                        },
                        expression: "addForm.content",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "codeContent",
                        staticStyle: {
                          background: "#1890ff",
                          borderColor: "#1890ff",
                        },
                        attrs: {
                          icon: "el-icon-upload2",
                          size: "mini",
                          type: "primary",
                        },
                        on: {
                          click: function ($event) {
                            _vm.codeContent = true
                          },
                        },
                      },
                      [_vm._v("\n            添加原内容\n          ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-upload",
                      {
                        attrs: {
                          action: "",
                          multiple: false,
                          "auto-upload": false,
                          "show-file-list": false,
                          "on-change": _vm.uploadVideo,
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "videoContent",
                            staticStyle: {
                              background: "#1890ff",
                              borderColor: "#1890ff",
                            },
                            attrs: {
                              slot: "trigger",
                              icon: "el-icon-video-camera",
                              size: "mini",
                              type: "primary",
                            },
                            slot: "trigger",
                          },
                          [_vm._v("\n              上传视频\n            ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "添加原内容",
              visible: _vm.codeContent,
              width: "900px",
            },
            on: {
              "update:visible": function ($event) {
                _vm.codeContent = $event
              },
              closed: function ($event) {
                _vm.code = ""
              },
            },
          },
          [
            _c("el-input", {
              attrs: { type: "textarea", rows: "18" },
              model: {
                value: _vm.code,
                callback: function ($$v) {
                  _vm.code = $$v
                },
                expression: "code",
              },
            }),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.codeContent = false
                      },
                    },
                  },
                  [_vm._v("取 消")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.sureCodeContent(_vm.code)
                      },
                    },
                  },
                  [_vm._v("确 定")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }