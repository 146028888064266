"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _user = require("@/api/user");

var _auth = require("@/utils/auth");

var _router = require("@/router");

var getDefaultState = function getDefaultState() {
  return {
    token: (0, _auth.getToken)(),
    userInfo: {}
  };
};

var state = getDefaultState();
var mutations = {
  RESET_STATE: function RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_USERINFO: function SET_USERINFO(state, data) {
    state.userInfo = data;
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var commit = _ref.commit;
    // const { loginName, loginPassword ,verificationCode} = userInfo;
    return new Promise(function (resolve, reject) {
      (0, _user.login)(userInfo).then(function (response) {
        var data = response.data;
        commit('SET_USERINFO', data);
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  setUserToken: function setUserToken(_ref2, token) {
    var commit = _ref2.commit;
    console.log('设置token', token);
    return new Promise(function (resolve, reject) {
      commit('SET_TOKEN', token);
      (0, _auth.setToken)(token, '.marsbigdata.com');
      resolve();
    });
  },
  // user logout
  logout: function logout(_ref3) {
    var commit = _ref3.commit,
        state = _ref3.state;
    return new Promise(function (resolve, reject) {
      // logout().then(() => {
      (0, _auth.removeToken)(); // must remove  token  first

      (0, _router.resetRouter)();
      commit('RESET_STATE');
      resolve(); // }).catch(error => {
      // reject(error)
      // })
    });
  },
  // remove token
  resetToken: function resetToken(_ref4) {
    var commit = _ref4.commit;
    return new Promise(function (resolve) {
      (0, _auth.removeToken)(); // must remove  token  first

      commit('RESET_STATE');
      resolve();
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;