"use strict";

var _interopRequireDefault = require("D:/1working/dcmm-project/web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vue = _interopRequireDefault(require("vue"));

var _vuex = _interopRequireDefault(require("vuex"));

var _getters = _interopRequireDefault(require("./getters"));

var _app = _interopRequireDefault(require("./modules/app"));

var _settings = _interopRequireDefault(require("./modules/settings"));

var _user = _interopRequireDefault(require("./modules/user"));

var _vuexPersistedstate = _interopRequireDefault(require("vuex-persistedstate"));

_vue.default.use(_vuex.default);

var store = new _vuex.default.Store({
  modules: {
    app: _app.default,
    settings: _settings.default,
    user: _user.default
  },
  getters: _getters.default,
  plugins: [(0, _vuexPersistedstate.default)()]
});
var _default = store;
exports.default = _default;