"use strict";

var _interopRequireDefault = require("D:/1working/dcmm-project/web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/1working/dcmm-project/web-admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));

var _axios = _interopRequireDefault(require("axios"));

var _vuex = require("vuex");

var _Logo = _interopRequireDefault(require("./Logo"));

var _SidebarItem = _interopRequireDefault(require("./SidebarItem"));

var _variables2 = _interopRequireDefault(require("@/assets/styles/variables.scss"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    SidebarItem: _SidebarItem.default,
    Logo: _Logo.default
  },
  created: function created() {// console.warn(this.$route);
    // console.error("我要做些什么");
    // this.$router.options.routes[3].children[1].hidden = true;
    // console.log(this.$router.options.routes);
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['sidebar'])), {}, {
    routes: function routes() {
      // console.log("router 触发");
      return this.$router.options.routes;
    },
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
          path = route.path; // if set path, the sidebar will highlight the path you set

      if (meta.activeMenu) {
        return meta.activeMenu;
      }

      return path;
    },
    showLogo: function showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables: function variables() {
      return _variables2.default;
    },
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    }
  })
};
exports.default = _default;