"use strict";

module.exports = {
  title: 'DCMM江苏省数据管理能力成熟度评估服务平台后台管理系统',

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: false,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: false
};