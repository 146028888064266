"use strict";

var _interopRequireWildcard = require("D:/1working/dcmm-project/web-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");

var _interopRequireDefault = require("D:/1working/dcmm-project/web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

require("D:\\1working\\dcmm-project\\web-admin\\node_modules\\core-js\\modules\\es6.array.iterator.js");

require("D:\\1working\\dcmm-project\\web-admin\\node_modules\\core-js\\modules\\es6.promise.js");

require("D:\\1working\\dcmm-project\\web-admin\\node_modules\\core-js\\modules\\es6.object.assign.js");

require("D:\\1working\\dcmm-project\\web-admin\\node_modules\\core-js\\modules\\es7.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

require("normalize.css/normalize.css");

var _elementUi = _interopRequireDefault(require("element-ui"));

require("element-ui/lib/theme-chalk/index.css");

var _mavonEditor = _interopRequireDefault(require("mavon-editor"));

require("mavon-editor/dist/css/index.css");

require("@/assets/styles/index.scss");

var _App = _interopRequireDefault(require("./App"));

var _store = _interopRequireDefault(require("./store"));

var _router = _interopRequireDefault(require("./router"));

require("@/icons");

require("@/permission");

var filters = _interopRequireWildcard(require("./utils/filters/filters"));

// A modern alternative to CSS resets
// global css
// icon
// permission control
Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */

if (process.env.NODE_ENV === 'production') {
  var _require = require("../mock"),
      mockXHR = _require.mockXHR;

  mockXHR();
}

_vue.default.use(_elementUi.default);

_vue.default.use(_mavonEditor.default);

_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});