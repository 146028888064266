"use strict";

var _interopRequireDefault = require("D:/1working/dcmm-project/web-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.changePwd = changePwd;
exports.getSelfInfo = getSelfInfo;
exports.getUserDetail = getUserDetail;
exports.getUserList = getUserList;
exports.login = login;
exports.logout = logout;
exports.muteUser = muteUser;
exports.unMuteUser = unMuteUser;
exports.updateRealNameVerify = updateRealNameVerify;
exports.updateUserInfo = updateUserInfo;

var _request = _interopRequireDefault(require("@/utils/request"));

// 修改密码
function changePwd(data) {
  return (0, _request.default)({
    url: '/ius/editPassword',
    method: 'post',
    data: data
  });
} // 登录


function login(data) {
  return (0, _request.default)({
    url: "/api/login",
    method: 'post',
    data: data
  });
}

function logout() {
  return (0, _request.default)({
    url: '/ius/user/logout',
    method: 'post'
  });
} // 获取用户列表


function getUserList(data) {
  return (0, _request.default)({
    url: '/ius/user/list',
    method: 'post',
    data: data
  });
} // 获取用户详情


function updateUserInfo(data) {
  return (0, _request.default)({
    url: '/ius/user/update',
    method: 'post',
    data: data
  });
} // 获取用户详情


function getUserDetail(params) {
  return (0, _request.default)({
    url: '/ius/user/detail',
    method: 'get',
    params: params
  });
} // 获取用户详情


function getSelfInfo(params) {
  return (0, _request.default)({
    url: '/ius/user/selfLoginInfo',
    method: 'get',
    params: params
  });
} // 实名认证


function updateRealNameVerify(data) {
  return (0, _request.default)({
    url: '/ius/user/realNameVerifyBackground',
    method: 'post',
    data: data
  });
} //用户禁言


function muteUser(data) {
  return (0, _request.default)({
    url: '/ius/user/banUser',
    method: 'post',
    data: data
  });
} //解除用户禁言


function unMuteUser(data) {
  return (0, _request.default)({
    url: '/ius/user/unBanUser',
    method: 'post',
    data: data
  });
}